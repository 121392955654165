
import { Component, Vue } from 'vue-property-decorator';
import DropsFeed from '../../components/Layout/DropsFeed.vue';
import TextHeader from '../../components/Layout/TextHeader.vue';
import PromoBanner from '../../components/PromoBanner.vue';
import Header from '../../components/Layout/Header.vue';
import Footer from '../../components/Layout/Footer.vue';
import ContractPrizeMenu from '../../components/Contract/ContractPrizeMenu.vue';
import Breadcrumbs from '@/components/Layout/Breadcrumbs.vue';

@Component({
  components: {
    Header,
    Footer,
    DropsFeed,
    TextHeader,
    PromoBanner,
    ContractPrizeMenu,
    Breadcrumbs,
  },
})
export default class NotFound extends Vue {
  created() {
    this.$setPageTitle('Страница не найдена | DOTALOOT');
  }

  mounted() {
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex, nofollow';
    document.head.appendChild(meta);
  }

  beforeDestroy() {
    // Remove the tag when the component is destroyed
    const metaTags = document.head.querySelectorAll('meta[name="robots"]');
    metaTags.forEach(metaTag => document.head.removeChild(metaTag));
  }
}
